import React, { useState, useEffect } from 'react';
import { verifyEmail } from '../app/user';

function VerfiyEmail(props) {
  const [status, setStatus] = useState('Loading...');

  const initialise = async () => {
    setStatus('Verifying email...');
    const response = await verifyEmail(props.token);
    console.log(response);
    if (response.status === 200) setStatus('Email verified. You may now close this window.');
    else setStatus('Error verifying email. Please try again later.'); 
  }

  useEffect(() => {
    initialise();
  }, []);

  return (
    <div id='gateway'>
      {status}
    </div>
  );
}

export default VerfiyEmail;