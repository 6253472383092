import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import demoImg from '../assets/appImg.png';

function Landing() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [enquiry, setEnquiry] = useState('');

  const contact = (e) => {
    e.preventDefault();
    if (name === '' || email === '' || enquiry === '') return alert('Please fill in all fields');
    window.location.href =
      `https://docs.google.com/forms/d/e/1FAIpQLSegTV0BwaJaTJ9QKRmxnVprS0jzCP_suLYB4BpdZfl_92xgEg/formResponse?usp=pp_url&entry.285148176=${name}&entry.151665416=${email}&entry.1346656972=${enquiry}`;
  }

  return (
    <>
      <div id='background'></div>

      <div id='gateway'>
        <Nav />

        <div id='landing'>
          <h1>Accept Pi Transactions <br /> Smoothly &amp; Securely</h1>
          <p>Simplify your app's transaction process with our intuitive Pi eCard APIs
            for a simple, secure and reliable way to accept Pi both
            in and out of the Pi Browser.
          </p>
          <Link className='largeBtn' to='/register'>Get Started</Link>
        </div>

        <div id='features'>
          <h1>How it works</h1>
          <div>
            <span>
              <p>
                <b>1.</b>
                Create a free account here choose one of our easy-to-use transaction solutions.
              </p>
            </span>
            <span>
              <p>
                <b>2.</b>
                Use our simple API SDK, or our no-code button, to begin accepting Pi on your website.
              </p>
            </span>
            <span>
              <p>
                <b>3.</b>
                Take advantage of our signature Pi transactions, low-code buttons, subscriptions and more!
              </p>
            </span>
          </div>

          <a href='https://docs.piecard.app'>See Docs <i className='fas fa-external-link'></i></a>
        </div>

        <img id='demoImg' src={demoImg} alt='Pi eCard screens' />

        <div id='solutions'>
          <h1>Solutions</h1>
          <ul>
            <li>Developer-friendly Pi transactions <i style={{ color: 'rgba(0, 128, 0, 0.5)' }}>Early access</i></li>
            <li>Both U2A and A2U transactions <i style={{ color: 'rgba(0, 128, 0, 0.5)' }}>Early access</i></li>
            <li>Low-code transaction button <i style={{ color: 'rgba(0, 128, 0, 0.5)' }}>Early access</i></li>
            <li style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = 'https://github.com/decentappsltd/PieCard_WooCommerce'}>Plugin for WooCommerce transactions <i style={{ color: 'rgba(0, 128, 0, 0.5)' }}>New!</i></li>
            <li>Automated subscriptions <i>Coming soon</i></li>
          </ul>
          <br />
          <Link className='largeBtn' to='/pricing'>See plans</Link>
        </div>

        <div id='contact'>
          <h4>Large or bespoke needs?</h4>
          <h1 style={{ marginTop: '-20px' }}>Get in touch</h1>
          <form>
            <input style={{ width: '100%', marginBottom: '10px' }} type="text" placeholder="Name" onChange={e => setName(e.target.value)} />
            <br />
            <input style={{ width: '100%', marginBottom: '10px' }} type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <br />
            <input style={{ width: '100%', marginBottom: '10px', height: '100px' }} type="text" placeholder="Enquiry" onChange={e => setEnquiry(e.target.value)} />
            <br />
            <button id="contact" value="Submit" onClick={e => contact(e)} >Send</button>
          </form>
        </div>
        <br />

        <Footer />
      </div>
      <div id='footerBG'></div>
    </>
  );
}

export default Landing;