import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import '../styles/Register.scss';
import { loginClient, createProfile, getProfile } from '../app/user';

export default function Register() {
  const [login, setLogin] = useState(false);
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [wallet, setWallet] = useState('');
  const [business, setBusiness] = useState(false);

  const appLogin = async (e) => {
    e.preventDefault();
    loginClient(username, password)
      .then(async (res) => {
        if (res.status === 200) {
          const profile = await getProfile(true);
          if (profile) window.location.href = '/dashboard';
          else setStep(2);
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Unexpected error. Please try again later.');
      })
  }

  const addEmail = (e) => {
    e.preventDefault();
    console.log(email, business)
    createProfile(email, wallet, business)
      .then((res) => {
        console.log(res);
        if (res.status === 201) setStep(3);
      })
      .catch((err) => {
        console.log(err);
        alert('Unexpected error. Please try again later.');
      })
  }

  const returningLogin = (e) => {
    e.preventDefault();
    loginClient(username, password)
      .then((res) => {
        console.log(res);
        if (res.status === 200) window.location.href = '/dashboard';
      })
      .catch((err) => {
        console.log(err);
        alert('Unexpected error. Please try again later.');
      })
  }

  return (
    <>
      <div id="navBG"></div>
      <div id='gateway' className="register">
        <Nav />

        <div id='register'>
          {login === false ?
            <div className="registerProcess">
              <h2>Create an API profile</h2>
              <p>Already have an account? <a onClick={() => setLogin(true)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Login</a> </p>

              {
                step === 1 &&
                <div className="step">
                  <h3>Step 1:</h3>
                  <h4>Please login to your Pi eCard account</h4>
                  <i>If you don't have a Pi eCard account or password, please go <a href='pi://piecard.app'>here</a> to create one.</i>

                  <form>
                    <input type='text' placeholder='Username' onChange={e => setUsername(e.target.value)} /><br />
                    <input type='password' placeholder='Password' onChange={e => setPassword(e.target.value)} /><br />
                    <button type='submit' onClick={appLogin}>Next</button>
                  </form>
                </div>
              }

              {
                step === 2 &&
                <div className="step">
                  <h3>Step 2</h3>
                  <h4>Enter an email address to backup your account and a wallet address to recieve payments</h4>
                  <form>
                    <input type='text' placeholder='Email' onChange={e => setEmail(e.target.value)} /><br />
                    <input type='text' placeholder='Pi Mainnet Wallet Address' onChange={e => setWallet(e.target.value)} /><br />
                    <input type='checkbox' onChange={e => setBusiness(e.target.checked)} id='business' />
                    <label htmlFor='business'>Business account</label><br />
                    <button type='submit' onClick={addEmail}>Next</button>
                  </form>
                </div>
              }

              {
                step === 3 &&
                <div className="step">
                  <h3>Step 3</h3>
                  <h4>Please validate your email address. You will find a link to do this in your inbox.</h4>
                  <br /><br /><br />
                  <Link id='dashboardLink' to='/dashboard'>Go to dashboard</Link>
                </div>
              }
            </div> :
            <div className="registerProcess">
              <h2>Login</h2>
              <p>Don't have an account? <a onClick={() => setLogin(false)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Register</a> </p>

              <div className="step">
                <form>
                  <input type='text' placeholder='Username' onChange={e => setUsername(e.target.value)} /><br />
                  <input type='password' placeholder='Password' onChange={e => setPassword(e.target.value)} /><br />
                  <button type='submit' onClick={returningLogin}>Login</button>
                </form>
              </div>
            </div>
          }
        </div>

        <Footer />
      </div>
      <div id="footerBG"></div>
    </>
  );
}