import React from 'react';
import Logo from '../assets/logo.png';

class NoPage extends React.Component {
  render(){
    return (
      <>
        <span id="notFound" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px', height: 'calc(100vh - 40px)' }}>
          <h2 style={{ textAlign: 'center' }}>404 <br /><br /> We couldn't find this page :(</h2>
          <img onClick={() => window.location.href = '/'} src={Logo} style={{ height: '150px', width: '150px', marginTop: '30vh' }} />
        </span>
      </>
    );
  }
}

export default NoPage;