import React, { useState, useEffect } from 'react';

function Success() {

  return (
    <div id='gateway'>
      Success
    </div>
  );
}

export default Success;