import React from 'react';

export default function Transaction(props) {
  return (
    <>
      <article className={`${props.data.sandbox === false ? 'historyStyleLive' : 'historyStyleTest'}`} key={props.data._id}>
        {
          props.data.sandbox === true && <p className='renderTest'><div className='testMode'></div> Sandbox <div className='testMode'></div></p>
        }
        <b>{props.data.amount} Pi received from {props.data.client}</b>
        <p className='renderMemo'>Memo: {props.data.memo}</p>
        <p className='renderDate'>{props.data.date.substring(0, 10)}</p>
        {
          props.data.txid ?
          <a href={'https://minepi.com/blockexplorer/tx/' + props.data.txid}>View Transaction</a> :
          <p className='renderId'>ID: <i>{props.data.paymentID}</i></p>
        }
      </article>
    </>
  );
}