import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getProfile, updateProfile, createAccessToken } from '../app/user';
import '../styles/Dashboard.scss'
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { AccessToken } from '../components/AccessToken';
import Withdrawal from '../components/Withdrawal';
import Transaction from '../components/Transaction';
import { PaymentButton, CreatePaymentButton } from '../components/PaymentButton';

function Dashboard() {
  const [client, setClient] = useState({
    username: '',
    email: '',
    wallet_address: '',
    business: false,
    business_name: '',
    isEmailVerified: true,
    plan: 0,
    balance: 0,
    testBalance: 0,
    secretKey: '',
    _id: null,
    usage: [],
    history: [],
    accessTokens: [],
    paymentButtons: []
  });
  const [plan, setPlan] = useState('');
  const [usage, setUsage] = useState(0);
  const [limit, setLimit] = useState(0);
  const [createToken, setCreateToken] = useState(false);
  const [changeWebhook, setChangeWebhook] = useState(false);
  const [webhook, setWebhook] = useState('');
  const [updateStatus, setUpdateStatus] = useState('Update');
  const [tokenStatus, setTokenStatus] = useState('Create New Token');
  const [accessTokenUrl, setAccessTokenUrl] = useState('');
  const [revealKey, setRevealKey] = useState(false);
  const [showCreatePaymentBtn, setShowCreatePaymentBtn] = useState(false);
  const [withdrawal, setWithdrawal] = useState(false);

  const initialise = async () => {
    const client = await getProfile();
    setClient(client);

    switch (client.plan) {
      case 0:
        setPlan('Free');
        setLimit(50);
        break;
      case 1:
        setPlan('Standard');
        setLimit(100);
        break;
      case 2:
        setPlan('Premium');
        setLimit(1000);
        break;
      default:
        setPlan('Custom');
        if (client.limit) setLimit(client.limit);
        else setLimit(1000);
        break;
    }

    const planUsages = client.usage.sort(function (a, b) { return b.count - a.number });
    if (planUsages[0]) setUsage(planUsages[0].count);
  }

  useEffect(() => {
    initialise();
  }, []);

  const exportHistory = () => {
    const rows = [];
    for (const transaction of client.history) {
      rows.unshift(
        [
          transaction.date,
          transaction.amount,
          transaction.client,
          transaction.memo,
          transaction.sandbox == true ? 'sandbox mode' : 'live mode'
        ]
      );
    }
    rows.unshift(['Date', 'Amount', 'Client', 'Memo', 'Sandbox']);

    let csvContent = 'data:text/csv;charset=utf-8,';
    for (const row of rows) {
      csvContent += row.join(',').replace('#', 'Number ') + '\n';
    }

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "piecard_transactions.csv");
    document.body.appendChild(link);

    link.click();
  }

  const createNewAccessToken = async (url) => {
    setTokenStatus('Creating...');
    setCreateToken(false);
    const updatedClient = await createAccessToken(url);
    if (updatedClient) setClient(updatedClient);
    setTokenStatus('Done');
    setTimeout(() => {
      setTokenStatus('Create New Token');
    }, 2000);
  }

  const updateWebhook = async () => {
    setUpdateStatus('Updating...');
    setChangeWebhook(false);
    const updatedClient = await updateProfile({ webhook });
    if (updatedClient) setClient(updatedClient);
    setUpdateStatus('Done');
    setTimeout(() => {
      setUpdateStatus('Update');
    }, 2000);
  }

  const editBusinessName = () => {
    const newName = prompt('Enter a new business name (this will appear when your customers make a transaction to you)');
    if (newName) {
      updateProfile({ business_name: newName });
      setClient({ ...client, business_name: newName });
    }
  }

  return (
    <>
      <div id='navBG'></div>
      <div id='gateway' className='dashboard'>
        <Nav />

        {client.isEmailVerified ?
          <div id='dashboard'>
            <h2>Welcome back, {client.username} </h2>

            <div className={`border section plan-${plan}`}>
              <p>{client.email}</p>
              <p style={{ wordBreak: 'break-word' }}>{client.wallet_address}</p>
              <p>{client.business ? 'Business' : 'Personal'}</p>
              {/* <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '20px' }}>
                <p>Plan: {plan}</p>
                <Link to='/pricing' id='upgradeBtn'>Upgrade</Link>
              </span> */}
              {
                client.business &&
                <p>Business Name: {client.business_name || 'Add a business name'} <i className='fas fa-pencil-alt' style={{ cursor: 'pointer' }} onClick={editBusinessName}></i></p>
              }
              <p>Usage: {usage} successful transactions</p>
            </div>

            <div className='section'>
              <h3>Earnings</h3>

              <div className='border'>
                <p id='balance'>Total: {client.balance} <i>Pi</i></p>
                <p id='testBalance'>Sandbox Total: {client.testBalance}</p>
              </div>
              <br />
            </div>

            <div className='section'>
              <h3>Developer Keys</h3>

              <div className='border'>
                <p>
                  <span onClick={() => { navigator.clipboard.writeText(client.secretKey); alert('Copied') }}>
                    Secret key: {!revealKey ? '**************' : `${client.secretKey}`}
                  </span>
                  <i id='revealKey' onClick={() => setRevealKey(!revealKey)}>{!revealKey ? 'Reveal' : 'Hide'}</i>
                </p>

                <p onClick={() => { navigator.clipboard.writeText(client._id); alert('Copied') }}>
                  Client ID: {client._id}
                </p>
              </div>
            </div>

            <div className='section'>
              <h3>Access Tokens</h3>

              <div className='border' style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                <br />
                {
                  client.accessTokens.map((token, index) => {
                    return <AccessToken key={index} data={token} clientId={client._id} setClient={setClient} />
                  })
                }

                {
                  client.accessTokens.length === 0 &&
                  <>
                    <i>Create an access token to connect your application...</i><br />
                  </>
                }
                <br />
              </div>

              <br />

              {
                createToken ?
                  <div className='changeWebhook'>
                    <input type='text' placeholder='Enter website URL' onChange={e => setAccessTokenUrl(e.target.value)} />
                    <span id='newTokenButtons'>
                      <button onClick={() => createNewAccessToken(accessTokenUrl)}>Save</button>
                      <a onClick={() => setCreateToken(false)}>Cancel</a>
                    </span>
                  </div> :
                  <button onClick={() => setCreateToken(!createToken)}>{tokenStatus}</button>
              }
            </div>

            <div className='section'>
              <h3>Manage Webhook</h3>

              <div className='border'>
                <p>Webhook URL: <br /> {client.webhook ? client.webhook : 'No webhook is set'}</p>
              </div>

              <br />
              {
                changeWebhook ?
                  <div className='changeWebhook'>
                    <input type='text' placeholder='New Webhook URL' onChange={e => setWebhook(e.target.value)} />
                    <span id='changeWebhookButtons'>
                      <button onClick={updateWebhook} id='saveBtn'>Save</button>
                      <a onClick={() => setChangeWebhook(false)}>Cancel</a>
                    </span>
                  </div> :
                  <button onClick={() => setChangeWebhook(!changeWebhook)}>{updateStatus}</button>
              }
            </div>

            <div className='section'>
              <h3>Transactions Buttons</h3>

              <div className='history border'>
                <p style={{ textAlign: 'justify' }}>Low-code transaction buttons allow you to accept Pi on your website.</p>
                {
                  client.paymentButtons.map((button, index) => {
                    return <PaymentButton key={index} data={button} clientId={client._id} setClient={setClient} />
                  })
                }

                {
                  client.paymentButtons.length === 0 &&
                  <i style={{ width: '100%', textAlign: 'center', display: 'inline-block' }}>Your payment buttons will appear here...<br /><br /></i>
                }
              </div>

              <br />
              <button id='createPaymentButton' onClick={() => setShowCreatePaymentBtn(!showCreatePaymentBtn)}>Create a Button</button>
              {
                showCreatePaymentBtn &&
                <CreatePaymentButton setClient={setClient} close={() => setShowCreatePaymentBtn(false)} />
              }
            </div>

            <div className='section'>
              <h3>Transaction History</h3>

              <div className='history border'>
                {client.history.map((transaction, index) => {
                  return <Transaction key={index} data={transaction} />
                })}
                {
                  client.history.length === 0 &&
                  <i style={{ width: '100%', textAlign: 'center', display: 'inline-block', margin: '20px 0' }}>
                    Your transaction history will appear here...
                  </i>
                }
              </div>
              <br />
              <button onClick={exportHistory}>Export</button>
            </div>
          </div> :
          <div id='dashboard'>
            <h2>Please verify your email</h2>
            <p style={{ textAlign: 'justify' }}>
              We've sent a verification email to {client.email}. Please check your inbox and click the link to verify your email.
            </p>

            <button onClick={() => window.location.reload()} style={{ marginTop: '20px' }}>Refresh</button>
          </div>
        }

        <Footer />
      </div>
      <div id='footerBG'></div>
    </>
  );
}

export default Dashboard;