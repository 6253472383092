import axios from 'axios';

// const urlAPI = 'http://localhost:5001';
const urlAPI = 'https://server.piecard.app';

async function loginClient(username, password) {
  try {

    const config = { username, password };
    const response = await axios.post(`${urlAPI}/register/app`, config);

    if (response.status === 200) {
      const token = response.data.token;
      sessionStorage.removeItem("userSession");
      localStorage.removeItem("userSession");
      sessionStorage.setItem("userSession", token);
      localStorage.setItem("userSession", token);
      localStorage.setItem("username", username);
    }

    return response;

  } catch (error) {
    console.log(error);
    alert('Invalid username or password');
  }

  return { status: 401 };
}

async function createProfile(email, wallet_address, business) {
  try {
    const token = localStorage.getItem("userSession");
    const config = { email, wallet_address, business };
    const response = await axios.post(`${urlAPI}/client/register`, config, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    console.log(response)

    return response;

  } catch (error) {
    console.log(error);
    alert(error.response.data.message || 'Email already exists.');
  }

  return { status: 401 };
}

async function getProfile(register) {
  try {
    const token = localStorage.getItem("userSession");

    const response = await axios.get(`${urlAPI}/client/account`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });

    return response.data.client;

  } catch (error) {
    console.log(error);
    if (register) return false;
    else window.location.href = '/register';
  }
}

async function updateProfile(data) {
  try {
    const token = localStorage.getItem("userSession");

    const response = await axios.put(`${urlAPI}/client/account`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });

    return response.data.client;

  } catch (error) {
    console.log(error);
    if (data.webhook) return alert('Invalid webhook URL. Please try again.');
    else if (data.email) return alert('Invalid email address. Please try again.');
    else return alert('Unable to update profile. Please try again.');
  }
}

async function createAccessToken(url) {
  try {
    const token = localStorage.getItem("userSession");

    const response = await axios.post(`${urlAPI}/client/access-token`, { url }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });

    if (response.data.success === true) return response.data.client;
    else return false;

  } catch (error) {
    console.log(error);
    return alert(error.response.data.message);
  }
}

async function deleteAccessToken(id) {
  try {
    const token = localStorage.getItem("userSession");

    const response = await axios.delete(`${urlAPI}/client/access-token/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    
    return response.data;

  } catch (error) {
    console.log(error);
    return alert('Unable to delete access token. Please try again.');
  }
}

async function verifyEmail(token) {
  console.log(token)
  const response = await axios.post(`${urlAPI}/client/validateEmail`, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });

  return response;
}

async function clientPaymentButton(data, method, setClient, close) {
  try {
    const token = localStorage.getItem("userSession");

    let url;
    if (data.buttonId) url = `${urlAPI}/client/button/${data.buttonId}`;
    else url = `${urlAPI}/client/button`;

    axios({
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin",
      data
    }).then((response) => {
      console.log(response)
      if (setClient) setClient(response.data.client);
      if (close) close();
      return response.data;
    });

  } catch (error) {
    console.log(error);
    return alert('Unable to create payment button. Please try again.');
  }
}

async function withdraw(amount) {
  const confirm = window.confirm(`Are you sure you want to withdraw ${amount} Pi?`);
  if (!confirm) return;

  try {
    const token = localStorage.getItem("userSession");

    const response = await axios.post(`${urlAPI}/client/withdraw`, { amount }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });

    return response.data;

  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

export {
  loginClient,
  createProfile,
  getProfile,
  updateProfile,
  createAccessToken,
  deleteAccessToken,
  verifyEmail,
  clientPaymentButton,
  withdraw
}