import React, { useState, useEffect } from 'react';
import { clientPaymentButton } from '../app/user';

export function PaymentButton(props) {
  const [showCode, setShowCode] = useState(false);
  const [edit, setEdit] = useState(false);

  const deleteButton = async () => {
    const confirmDelete = await window.confirm('Are you sure you want to delete this payment button?');
    if (!confirmDelete) return;
    const deletedButton = await clientPaymentButton({ buttonId: props.data._id }, 'delete', props.setClient);
    if (deletedButton.success === true) props.setClient(deletedButton.client);
  }

  return (
    <>
      <div className={`paymentButton ${props.data.sandbox && 'sandboxPaymentBtn'}`}>
        {
          props.data.sandbox === true && <p className='renderTest'><div className='testMode'></div> Sandbox <div className='testMode'></div></p>
        }
        <p>
          {
            props.data.memo.toString().length < 20 ?
              <span>{props.data.memo}</span> :
              <span>{props.data.memo.substring(0, 20)}...</span>
          }
          <span style={{ fontWeight: 'bold', margin: '0 20px' }}>•</span>
          <span>{props.data.amount} Pi</span>
        </p>
        <div className='paymentButtonInteractions'>
          <button onClick={() => setShowCode(!showCode)}>Show Code</button>
          <a className='fas fa-edit' style={{ height: '27px', boxSizing: 'border-box', margin: '0 10px' }} onClick={() => setEdit(!edit)}></a>
          <a className='fas fa-trash' style={{ height: '27px', boxSizing: 'border-box' }} onClick={deleteButton}></a>
        </div>
      </div>

      {showCode &&
        <>
          <div id="buttonPaymentCode">
            <h3>Payment Button Code</h3>
            <p>Copy and paste the code below into your website to create a payment button.</p>
            <p id='codeSnippet' onClick={e => { navigator.clipboard.writeText(e.target.textContent); alert('Copied') }}>
              {"<script src='https://gateway.piecard.app/payment.js'></script>"}<br />
              {`<button id='${props.data._id.toString()}' style="background:#b44afb;color:white;border:none;border-radius:20px;padding:10px 30px;font:small-caps bold 20px 'DM Sans',sans-serif;">Pay with Pi eCard</button>`}<br />
              {"<script>"}<br />
              {` const piecard${props.data._id.toString()} = new window.PieCard('${props.clientId}'); `}<br />
              {` document.getElementById('${props.data._id.toString()}').addEventListener('click', () => { `}<br />
              {` piecard${props.data._id.toString()}.createButtonPayment('${props.data._id}'); `}<br />
              {" });"}<br />
              {"</script>"}<br />
            </p>
          </div>
          <div id='tint' onClick={() => setShowCode(false)}></div>
        </>
      }

      {edit && <CreatePaymentButton newButton={props.data} edit={true} setClient={props.setClient} close={() => setEdit(false)} />}
    </>
  );
}

export function CreatePaymentButton(props) {
  const [newButton, setNewButton] = useState({
    amount: null,
    memo: '',
    sandbox: false,
    metadata: {},
    successUrl: '',
    cancelUrl: '',
  });

  const handleCreatePaymentButton = async () => {
    if (!newButton.amount || newButton.memo === '' || newButton.successUrl === '' || newButton.cancelUrl === '') return alert('Please fill out all fields');
    if (props.edit) await clientPaymentButton({ ...newButton, buttonId: props.newButton._id }, 'put', props.setClient, props.close);
    else await clientPaymentButton(newButton, 'post', props.setClient, props.close);
  }

  useEffect(() => {
    if (props.edit) setNewButton(props.newButton);
  }, [props.newButton])

  return (
    <>
      <div id="createPaymentBtn">
        {
          props.edit ?
            <h3>Create Payment Button</h3> :
            <h3>Edit Payment Button</h3>
        }
        <p>Payment buttons allow you to accept payments on your website.</p>

        <div>
          <input type="number" placeholder='Amount (Pi)' value={newButton.amount} onChange={e => setNewButton({ ...newButton, amount: Number(e.target.value) })} />
          <input type="text" placeholder='Memo' value={newButton.memo} onChange={e => setNewButton({ ...newButton, memo: e.target.value })} />
          <input type="text" placeholder='Success URL' value={newButton.successUrl} onChange={e => setNewButton({ ...newButton, successUrl: e.target.value })} />
          <input type="text" placeholder='Cancel URL' value={newButton.cancelUrl} onChange={e => setNewButton({ ...newButton, cancelUrl: e.target.value })} />
          <label style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>Sandbox Mode
            <input style={{ width: '20px' }} type="checkbox" checked={newButton.sandbox} onChange={e => setNewButton({ ...newButton, sandbox: e.target.checked })} />
          </label>
        </div>
        <br /><br />

        <span style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <button onClick={handleCreatePaymentButton}>{props.edit ? 'Edit' : 'Create' }</button>
          <button style={{ background: 'none', color: '#5b4afb' }}
            onClick={() => { setNewButton({ amount: 0, memo: '', sandbox: false, metadata: {}, successUrl: '', cancelUrl: '' }); props.close() }}>Cancel</button>
        </span>
      </div>

      <div id='tint' onClick={props.close}></div>
    </>
  )
}