import React from 'react';
import { deleteAccessToken } from '../app/user';

export function AccessToken(props) {
  const deleteToken = async () => {
    const confirmDelete = await window.confirm('Are you sure you want to delete this access token?');
    if (!confirmDelete) return;
    const deletedToken = await deleteAccessToken(props.data.token);
    if (deletedToken.success === true) props.setClient(deletedToken.client);
  }

  const copyToken = () => {
    navigator.clipboard.writeText(props.data.token);
    alert('Copied');
  }

  return (
    <div className={`paymentButton ${props.data.sandbox && 'sandboxPaymentBtn'}`}>
      <p>
        URL: <span>{props.data.url}</span>
      </p>
      <p style={{ wordWrap: 'break-word' }}>
        Token: <span>{props.data.token}</span>
      </p>
      <div className='paymentButtonInteractions'>
        <button onClick={copyToken}>Copy</button>
        <a className='fas fa-trash' style={{ height: '27px', boxSizing: 'border-box' }} onClick={deleteToken}></a>
      </div>
    </div>
  );
}