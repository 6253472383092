import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import './index.scss';
import './styles/fonts/all.min.css';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Pricing from './pages/Pricing';
import Success from './pages/Success';
import VerifyEmail from './pages/VerifyEmail';
import NoPage from './pages/NoPage';

function OpenVerifyEmail() {
  let { token } = useParams();
  return <VerifyEmail token={token} />;
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/success" element={<Success />} />
        <Route path="/confirm/:token" element={<OpenVerifyEmail />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  ); 
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
